

// eslint-disable-next-line no-shadow
export enum SensorsType {

  /**
   * 神策埋点
   * @value visit_landing_page
   * @Infomation 用户访问落地页
   */
  visit_landing_page = 'visit_landing_page',

    /**
   * 神策埋点
   * @value cilck_get_verification_code
   * @Infomation 用户获取验证码
   */
  cilck_get_verification_code = 'cilck_get_verification_code',

  /**
   * 神策埋点
   * @value click_login
   * @Infomation 户点击登录
   */
  click_login = 'click_login',

  /**
   * 神策埋点
   * @value visit_form_page
   * @Infomation 用户访问表单页
   */
  visit_form_page = 'visit_form_page',

  /**
   * 神策埋点
   * @value click_submit_form_info
   * @Infomation 用户点击提交表单
   */
  click_submit_form_info = 'click_submit_form_info',

  /**
   * 神策埋点
   * @value visit_authorization_page
   * @Infomation 用户访问授权页
   */
  visit_authorization_page = 'visit_authorization_page',

  /**
   * 神策埋点
   * @value click_authorize
   * @Infomation 用户点击授权
   */
  click_authorize = 'click_authorize',

  /**
   * 神策埋点
   * @value visit_result_page
   * @Infomation 用户访问结果页
   */
  visit_result_page = 'visit_result_page',

  /**
   * 神策埋点
   * @value click_download_app
   * @Infomation 用户点击下载 App
   */
  click_download_app = 'click_download_app',

  /**
   * 神策埋点
   * @value click_backend_h5_product
   * @Infomation 用户点击后端 H5 产品
   */
  click_backend_h5_product = 'click_backend_h5_product'
}