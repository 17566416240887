/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react'
import Header from '@/components/header/header'
import styles from './info-audit-agreement.module.scss'
import classNames from 'classnames'
import { download, downloadFile, getParams, formatTime, Storage } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'antd-mobile'
import indexApi from '@/apis'
import { TOKEN } from '@/constants/storage'
import moment from 'moment'
import indexApiInfo from '@/apis/index'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface Props{}
// 个人信息授权书
const RegisterAgreement:React.FC<Props> = props => {
  const hostNameObj = getHostNameObj()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)

  const [isShowHeader, setIsShowHeader] = useState(false)
  const [name] = useState(hostNameObj.appName)
  const [search] = useSearchParams()
  const [agreementContent, setAgreementContent] = useState('')
  const [isAgreementLogInfo, setIsAgreementLogInfo] = useState(false)
  const [thirdpartyApiType, setThirdpartyApiType] = useState('')
  const [agreementTitle, setAgreementTitle] = useState('')
  const [userSignProtocolLogs, setUserSignProtocolLogs] = useState([])
  const [token, setToken] = useState('')


  let isFirstRun = true

  useEffect(() => {
    const title:any = search.get('title')
    setAgreementTitle(title)
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }

    let tokenTemp = Storage.get(TOKEN)
    const urlToken = search.get('token')
    if (urlToken) {
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
    } else if (tokenTemp) {
      setToken(tokenTemp)
    }
  }, [])

  // 静态麦吉优共享协议
  const mjyUserAgreement = async() => {
    let userLoginInfo: any = await indexApiInfo.getLoginUserInfo()
    let username
    let fillInfo = localStorage.getItem('fillInformation')
    if (fillInfo) {
      username = JSON.parse(fillInfo)?.realName
    }
    const htmlContent = `
      <p><br></p>
      <p style="text-align: center;"><strong>《用户个人信息共享授权协议》</strong></p>
      <p><span style="font-family: 宋体;">《用户个人信息共享授权协议》 (以下简称“本协议”)是由本公司作为个人信息处理者】</span></p>
      <p><span style="font-family: 宋体;">(以下称“我们”)与您所订立的有效合约</span></p>
      <p><strong>【审慎阅读】当您通过本页面使用我们提供的</strong></p>
      <p><strong>【第三方产品推荐】服务时，您的个人信息会进行共享。为了您的合法权益，请您在使用本服务前务必仔细阅读本协议的全部内容(特别是以粗体/下划线标注的内容)。如果您不同意本协议内容，或无法准确理解本协议任何条款的含义，请不要进行确定及后续操作【签约动作】您同意本协议以数据电文形式订立。如您通过网络页面点击“同意或“注册”或“申请”或“接受用户授权协议”(以网页显示为准)，即视为您已阅读理解本协议的全部内容并同意我们出于相关页面所述目的，将您的个人信息提供给为您提供【用户联登撞库】服务的其他个人信息处理者，本协议即生效</strong></p>
      <p><strong>【共享信息】您授权我们将您的</strong></p>
      <p><span style="font-family: 宋体;">手机号：${userLoginInfo.data!.mobile}</span></p>
      <p><strong>信息通过【加密通道/文件/接口方式】传输给第三方为您提供</strong><span style="font-family: 宋体;">【用户联登撞库】</span><strong>服务</strong><span style="font-family: 宋体;">。我们会要求该第三方依法使用您的上述信息。</span><strong>如果您不希望向第三方提供上述信息，请勿点击授权。</strong></p>
      <p><strong>您理解，第三方服务由相应的第三方提供并就以上服务及处理您个人信息的安全性独立承担责任。请您在授权同意前，仔细阅读并理解第三方的服务协议与隐私政策。因该第三方服务或其处理您的个人信息产生的纠纷，或第三方服务违反相关法律法规或者协议约定，或您在使用第三方服务过程中遭受了损失，请您与第三方协商解决。</strong></p>
      <p><strong>【争议解决】凡因本协议引起的任何与之有关的争议，首先应由该等争议的相关当事方通过友好协商解决，如无法友好协商解决的，任何一方均可向广州海珠区人民法院提起诉讼。</strong></p>
      <p><span style="font-family: 宋体;">用户姓名:【${username || ''}】</span></p>
      <p><span style="font-family: 宋体;">用户账号:【${userLoginInfo.data!.uid}】</span></p>
      <p><span style="font-family: 宋体;">日期：【${moment().format('YYYY-MM-DD hh:mm:ss')}】</span></p>
    `
    return htmlContent
  }

  useEffect(() => {
    if (!token) {
      return
    }
    if (!isFirstRun) {
      return
    }
    isFirstRun = false
    // 获取协议记录ID(给工作人员查看协议时使用)
    const userSignProtocolLogIds:any = search.get('userSignProtocolLogIds')
    const thirdpartyApiTypeCode:any = search.get('thirdpartyApiType')
    setThirdpartyApiType(thirdpartyApiTypeCode)
    const ids = userSignProtocolLogIds && JSON.parse(decodeURIComponent(userSignProtocolLogIds))
    const protocolType = search.get('protocolType')
    // // 如果拿到协议记录ID
    if (userSignProtocolLogIds) {
      setIsAgreementLogInfo(true)
      getUserSignProtocolLog(`ids=${ids}`)
      return
    }
    const id: any = search.get('agreementId')

    const productId: any = search.get('productId')
    // 协议内容修改处
    async function getAgreementInfo() {
      const res:any = await indexApi.getAgreementInfo(id, productId)
      if (adChannelCode) {
        if (channelValue?.includes('jfxd') && channelValue?.includes('jufu')) {
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, '广州市聚富互联网小额贷款有限公司')
        }
        // 判断是否是麦吉优
        if ((channelValue?.includes('mjy') || channelValue?.includes('hbjf')) && protocolType === '1') {
          let mjyAgreement = await mjyUserAgreement()
          res.content = mjyAgreement
        }
        // 如果是聚富渠道，将【广州海珠】替换成【深圳龙华】，在【】前面插入【广州市虫洞科技有限公司】
        if (channelValue?.includes('jufu') && agreementTitle === '聚客融联登默认协议') {
          res.content = res.content.replace(/广州海珠/g, '深圳龙华')
          if (channelValue?.includes('jfxd')) {
            res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, '广州市聚富互联网小额贷款有限公司')
          } else {
            res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, '深圳聚客融科技有限公司')
          }
          // 在【】前面插入【广州市虫洞科技有限公司】
          res.content = res.content.replace(/传输给\s*/, '传输给【广州市虫洞科技有限公司】')
        }
        if ((channelValue?.includes('jiezhanggui'))) {
          // 替换合作机构个人信息授权书被授权人所属
          res.content = res.content.replace(/所属广州虫洞跳跃信息科技有限公司/g, '属于本平台')
          // 替换个人信息授权书主体
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, '本平台')
        }
      }
      setAgreementContent(res.content)
    }
    getAgreementInfo()
  }, [token])

  const getUserSignProtocolLog = async(userSignProtocolLogIds:any) => {
    const res: any = await indexApi.getAgreementLogInfo(userSignProtocolLogIds)
    setUserSignProtocolLogs(res)
    // setAgreementContent(res.content)
  }

  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title={ agreementTitle } />
      }
      {
        <div>
          {
            isAgreementLogInfo ? <>
              {
                userSignProtocolLogs.map((item:any) => {
                  return <div key={item.id} style={{ marginBottom: '30px' }}>
                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    {/* 非填充，兼容非填充已签署协议的签署数据回显 */}
                    {
                      item.signFieldFlag !== 1 && <div>
                        {
                          // 504是比融的代号
                          thirdpartyApiType === '504' ? <>
                            <p>您的登录账号：【{ item.mobile }】</p>
                            <p>您的UID：【{ item.uid }】</p>
                          </> : <>
                            <p>用户姓名：【{ item.userName }】</p>
                            <p>用户账号：【{ item.userAccount }】</p>
                            <p>签署日期：【{ formatTime(item.signAt, 'YYYY-MM-DD') }】</p>
                          </>
                        }
                      </div>
                    }
                  </div>
                })
              }
            </> : <>
              <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
            </>
          }
        </div>
      }
    </div>
  )
}

export default RegisterAgreement